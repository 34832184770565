import type { IRoutes } from '~/types/common/route'

export const PAGES_ROUTES: IRoutes = {
  bookings: {
    path: '/bookings',
    label: 'Réservations',
    title: 'Réservations'
  }
}

export const PUBLIC_ROUTES: IRoutes = {
  login: {
    path: '/login',
    label: 'Connexion',
    title: 'Connexion'
  },
  forgotPassword: {
    path: '/forgot-password',
    label: 'Mot de passe oublié',
    title: 'Mot de passe oublié ?'
  },
  resetPassword: {
    path: '/reset-password',
    label: 'Nouveau mot de passe',
    title: 'Nouveau mot de passe'
  },
  privacyPolicy: {
    path: '/privacy-policy',
    label: 'Politique de confidentialité',
    title: 'Politique de confidentialité'
  }
}

export const PUBLIC_ROUTES_PATH: string[] = [
  PUBLIC_ROUTES.login.path,
  PUBLIC_ROUTES.forgotPassword.path,
  PUBLIC_ROUTES.resetPassword.path,
  PUBLIC_ROUTES.privacyPolicy.path
]

export const PUBLIC_ROUTES_NAME: string[] = ['reset-password-token']
