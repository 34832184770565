export default defineAppConfig({
  ui: {
    primary: 'green',
    gray: 'cool',
    tooltip: {
      default: {
        openDelay: 150,
        closeDelay: 300
      }
    },
    variables: {
      light: {
        background: '255 255 255',
        // background: '241 245 249',
        foreground: '30 43 68',
        defaultFontSize: '16px !important'
      },
      dark: {
        background: '17 16 21',
        foreground: '30 43 68',
        defaultFontSize: '16px !important'
      },
      header: {
        height: '6rem'
      },
      footer: {
        height: '6rem'
      }
    },

    icons: {
      dark: 'i-heroicons-moon-20-solid',
      light: 'i-heroicons-sun-20-solid',
      system: 'i-heroicons-computer-desktop-20-solid',
      search: 'i-heroicons-magnifying-glass-20-solid',
      external: 'i-heroicons-arrow-up-right-20-solid',
      chevron: 'i-heroicons-chevron-down-20-solid',
      hash: 'i-heroicons-hashtag-20-solid',
      menu: 'i-heroicons-bars-3-20-solid',
      close: 'i-heroicons-x-mark-20-solid',
      check: 'i-heroicons-check-circle-20-solid'
    },
    formGroup: {
      label: {
        base: 'text-foreground dark:text-foreground'
      },
      default: {
        size: 'xl'
      }
    },
    input: {
      padding: {
        xl: 'px-5 py-4'
      },
      default: {
        size: 'xl',
        padding: 'xl'
      }
    },
    table: {
      tr: {
        base: 'border-l-4 border-l-transparent',
        selected: 'bg-gray-50 dark:bg-gray-800/30 border-l-4 border-l-primary',
        active: 'hover:bg-gray-50 dark:hover:bg-gray-800/30 cursor-pointer hover:border-l-primary'
      },
      th: {
        base: 'sticky top-0 bg-[#F8FAFC] dark:bg-[#111015] z-10',
        color: 'text-gray-900 dark:text-white',
        font: 'font-semibold'
      },
      td: {
        color: 'text-gray-700 dark:text-gray-300'
      },
      tbody: 'divide-b dark:divide-b dark:divide-gray-800',
      divide: 'divide-y dark:divide-gray-700',
      progress: {
        wrapper: 'absolute inset-x-0 -bottom-[0.5px] p-0'
      }
    },
    button: {
      padding: {
        xl: 'px-5 py-4'
      },
      default: {
        size: 'xl'
      },
      color: {
        gray: {
          ghost: 'dark:hover:bg-gray-800/30'
        }
      }
    },
    card: {
      ring: 'ring-0'
    }
  }
})
