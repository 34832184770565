import { defineStore } from 'pinia'
import api from '~/api'
import { PUBLIC_ROUTES } from '~/constants/routes'
import type { AuthCredentials, AuthJwt } from '~/types/auth'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    accessToken: null,
    refreshToken: null
  }),
  actions: {
    async signIn(credentials: AuthCredentials) {
      const { data, error } = await useAPI(api.login(credentials))
      if (error.value) throw error.value

      const jwt: AuthJwt = data.value

      this.accessToken = jwt.accessToken
      this.refreshToken = jwt.refreshToken
    },
    async handleRefreshToken() {
      this.accessToken = this.refreshToken

      const { data, error } = await useAPI(api.refreshToken())
      if (error.value) throw error.value

      const jwt: AuthJwt = data.value
      this.accessToken = jwt.accessToken
      this.refreshToken = jwt.refreshToken
    },
    toLogin() {
      navigateTo(PUBLIC_ROUTES.login.path)
    },
    resetLoginState() {
      this.resetToken()
    },
    async logout() {
      this.resetLoginState()
      this.toLogin()
    },
    resetToken() {
      this.$reset()
    }
  },
  persist: true
  /* persist: {
    key: 'auth'
    // Todo: enable when production
    /!* storage: persistedState.cookiesWithOptions({
      sameSite: 'strict',
      secure: false, // TODO: enable if production,
      httpOnly: true,
      maxAge: 3600000
    }) *!/
  } */
})
