import { useJwt } from '@vueuse/integrations/useJwt'
import type { AuthJwtPayload } from '~/types/auth'

export function isAuthenticated(): boolean {
  const auth = useAuthStore()
  if (!auth?.accessToken) return false

  let isExpiredJwt = true
  try {
    isExpiredJwt = checkIsExpiredJwt(auth.accessToken)
    if (isExpiredJwt) {
      isExpiredJwt = checkIsExpiredJwt(auth.refreshToken)
      if (!isExpiredJwt) auth.handleRefreshToken()
    }
  } catch {
    console.log('Can\'t refresh token')
  }
  return !isExpiredJwt
}

export function checkIsExpiredJwt(encodedJwt: string): boolean {
  const { payload }: any = useJwt(encodedJwt)
  const decodedJwt: AuthJwtPayload = payload.value

  return Date.now() > decodedJwt.expirationDate
}
