import type { ResetPasswordDTO, ResetPasswordSettingsDTO } from '~/types/reset-password'
import type { BookingAssignDriverDTO, BookingRegulationDTO, BookingTableQueryFilters } from '~/types/booking'
import type { CustomFetchData } from '~/types/common/api'

export default {
  // AUTH

  login: (payload: any): CustomFetchData => ({ url: `/auth/admin/login`, options: { method: 'POST', body: payload } }),

  forgotPassword: (email: string): CustomFetchData => ({ url: '/admin/reset-password', options: { query: { email } } }),

  refreshToken: (): CustomFetchData => ({ url: `/auth/refresh`, options: { method: 'POST' } }),

  resetPasswordCheck: (token: string): CustomFetchData => ({ url: '/admin/reset-password/check-token', options: { method: 'PUT', query: { token } } }),

  resetPassword: (payload: ResetPasswordDTO): CustomFetchData => ({ url: `/admin/reset-password`, options: { method: 'PUT', body: payload } }),

  resetPasswordSettings: (payload: ResetPasswordSettingsDTO): CustomFetchData => ({ url: `/admin/settings/password`, options: { method: 'PUT', body: payload } }),

  // ACCOUNT
  getAccountInformations: (): CustomFetchData => ({ url: '/settings' }),

  // BOOKING
  getBookingTableMetadata: (): CustomFetchData => ({ url: '/back-office/bookings/filters' }),

  getBookingTabsFilters: (): CustomFetchData => ({ url: '/back-office/bookings/quick-filters' }),

  getBookingFiltered: (query: BookingTableQueryFilters): CustomFetchData => ({ url: '/back-office/bookings', options: { query } }),

  getBookingDetails: (id: string): CustomFetchData => ({ url: `/back-office/bookings/${id}` }),

  rejectBooking: (id: string): CustomFetchData => ({ url: `/back-office/bookings/${id}/reject`, options: { method: 'PATCH' } }),

  regulateBooking: (payload: BookingRegulationDTO): CustomFetchData => ({ url: `/back-office/bookings/regulate`, options: { method: 'PUT', body: payload } }),

  assignDriverBooking: (payload: BookingAssignDriverDTO): CustomFetchData => ({ url: `/back-office/bookings/assign-driver`, options: { method: 'PUT', body: payload } }),

  downloadBookingDocuments: (id: string, fileName: string) => useCustomAPI().download(`/back-office/bookings/${id}/documents`, {}, fileName)
}
