import type { AsyncData } from '#app'
import type { PickFrom } from '#app/composables/asyncData'
import type { DefaultAsyncDataErrorValue } from '#app/defaults'
import type { CustomFetchData } from '~/types/common/api'

export function useAPI(data: CustomFetchData): AsyncData<PickFrom<DataT, PickKeys> | DefaultT, ErrorT | DefaultAsyncDataErrorValue> {
  return useFetch(data.url, {
    ...data.options || {},
    $fetch: useNuxtApp().$api
  })
}
