import { default as bookingsZiqoHticGsMeta } from "/app/pages/bookings.vue?macro=true";
import { default as forgot_45password1UbT4qDpFeMeta } from "/app/pages/forgot-password.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as my_45accountuC2fkUFxhRMeta } from "/app/pages/my-account.vue?macro=true";
import { default as privacy_45policyOPxqa3IsSYMeta } from "/app/pages/privacy-policy.vue?macro=true";
import { default as _91token_93t4M12Pz5yOMeta } from "/app/pages/reset-password/[token].vue?macro=true";
export default [
  {
    name: "bookings",
    path: "/bookings",
    meta: bookingsZiqoHticGsMeta || {},
    component: () => import("/app/pages/bookings.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45password1UbT4qDpFeMeta || {},
    component: () => import("/app/pages/forgot-password.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginhHM0vSTW5jMeta || {},
    component: () => import("/app/pages/login.vue")
  },
  {
    name: "my-account",
    path: "/my-account",
    component: () => import("/app/pages/my-account.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: privacy_45policyOPxqa3IsSYMeta || {},
    component: () => import("/app/pages/privacy-policy.vue")
  },
  {
    name: "reset-password-token",
    path: "/reset-password/:token()",
    meta: _91token_93t4M12Pz5yOMeta || {},
    component: () => import("/app/pages/reset-password/[token].vue")
  }
]