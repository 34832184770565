import * as Sentry from '@sentry/nuxt'
import { useAuthStore } from '~/stores/auth'

export default defineNuxtPlugin((nuxtApp) => {
  const auth = useAuthStore()
  const config = useRuntimeConfig()

  const api = $fetch.create({
    baseURL: `${config.public.apiUrl}`,
    // onRequest({ request, options, error }) {
    onRequest({ options }) {
      if (auth.accessToken) {
        const headers = options.headers ||= {}
        if (Array.isArray(headers)) {
          headers.push(['Authorization', `Bearer ${auth.accessToken}`])
        } else if (headers instanceof Headers) {
          headers.set('Authorization', `Bearer ${auth.accessToken}`)
        } else {
          headers.Authorization = `Bearer ${auth.accessToken}`
        }
      }
    },
    async onResponseError({ response }) {
      if (response.status === 401) {
        await nuxtApp.runWithContext(() => navigateTo('/login'))
      } else if (response.status === 500) {
        if (import.meta.client) {
          toast.add({
            id: 'api_error',
            color: 'red',
            title: 'Une erreur est survenue',
            description: response._data.message,
            icon: 'i-heroicons-exclamation-triangle',
            timeout: 0
          })
        }
        Sentry.captureException(response)
      }
    }
  })

  // Expose to useNuxtApp().$api
  return {
    provide: {
      api
    }
  }
})
