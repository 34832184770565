import { PUBLIC_ROUTES, PUBLIC_ROUTES_NAME, PUBLIC_ROUTES_PATH } from '~/constants/routes'
import { isAuthenticated } from '~/utils/jwt'

const HOME_PATH = '/'
const LOGIN_PATH = PUBLIC_ROUTES.login.path

const isPublicRoute = (route): boolean => {
  return PUBLIC_ROUTES_PATH.includes(route.path)
    || (route.name && PUBLIC_ROUTES_NAME.includes(route.name.toString()))
}

export default defineNuxtRouteMiddleware((to) => {
  try {
    const isAuth = isAuthenticated()

    // Rediriger les utilisateurs non authentifiés vers la page de connexion
    if (!isAuth) {
      if (to.path === HOME_PATH || !isPublicRoute(to)) {
        return navigateTo(LOGIN_PATH)
      }
      return // Autoriser l'accès aux routes publiques
    }

    // Rediriger les utilisateurs authentifiés de la page de connexion vers la page d'accueil
    if (to.path === LOGIN_PATH) {
      return navigateTo(HOME_PATH)
    }
    // Autoriser l'accès à la route demandée pour les utilisateurs authentifiés
    return
  } catch (error) {
    console.error('Erreur dans le middleware d\'authentification:', error)
    // Rediriger vers une page d'erreur en cas de problème
    return navigateTo('/')
  }
})
